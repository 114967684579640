var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"JepxMinmaxChartLegend"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"JepxMinmaxChartLegend-colorWrapper"},[_c('div',{staticClass:"JepxMinmaxChartLegend-colorWrapper-item"},[_c('ul',{staticClass:"JepxMinmaxChartLegend-colorList"},[_c('li',{staticClass:"JepxMinmaxChartLegend-colorItem"},[_c('span',{staticClass:"JepxMinmaxChartLegend-colorItem-sample",style:({
              'border-bottom-color': _vm.currentColor.upperAverage,
              'border-bottom-style': 'solid',
            })}),_vm._v("最高値\n        ")]),_vm._v(" "),_c('li',{staticClass:"JepxMinmaxChartLegend-colorItem"},[_c('span',{staticClass:"JepxMinmaxChartLegend-colorItem-sample",style:({
              'border-bottom-color': _vm.currentColor.upperAverage,
              'border-bottom-style': 'dashed',
            })}),_vm._v("平均値\n        ")]),_vm._v(" "),_c('li',{staticClass:"JepxMinmaxChartLegend-colorItem"},[_c('span',{staticClass:"JepxMinmaxChartLegend-colorItem-sample",style:({
              'border-bottom-color': _vm.currentColor.lowerAverage,
              'border-bottom-style': 'solid',
            })}),_vm._v("最安値\n        ")])])])]),_vm._v(" "),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"JepxMinmaxChartLegend-y2Label"},[_c('span',{staticClass:"JepxMinmaxChartLegend-y2Label-unit"},[_vm._v("(円/kWh)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"JepxMinmaxChartLegend-y2Label"},[_c('span',{staticClass:"JepxMinmaxChartLegend-y2Label-unit"},[_vm._v("(円/kWh)")])])}]

export { render, staticRenderFns }