<template>
  <div class="AreaTabSelector">
    <a
      v-for="area in areas"
      :key="area.id"
      href="#"
      class="AreaTabSelector-tab"
      :class="{ 'is-selected': area.id === selectedAreaId }"
      @click.prevent="onClick(area.id)"
      ><span class="AreaTabSelector-sample" :style="{ 'background-color': area.chart.color }"></span
      >{{ area.name }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'AreaTabSelector',
  model: {
    prop: 'selectedAreaId',
    event: 'change-area-id',
  },
  props: {
    areas: {
      type: Array,
      required: true,
    },
    selectedAreaId: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    onClick(areaId) {
      this.$emit('change-area-id', areaId)
    },
  },
}
</script>

<style lang="scss" scoped>
@use '../../css/foundation/_vars.scss';

.AreaTabSelector {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0 16px;
}

.AreaTabSelector-sample {
  position: absolute;
  top: 15px;
  left: 8px;
  display: block;
  width: 6px;
  height: 6px;
  margin: auto;
  border-radius: 50%;
  transition: transform 0.2s ease-out;
}

.AreaTabSelector-tab {
  position: relative;
  display: block;
  flex: 0 0 9.6rem;
  width: 9.6rem;
  height: 3.6rem;
  padding: 2px 2px 0 8px;
  margin-right: 8px;
  font-family: vars.$font-family-narrow;
  font-size: 1.6rem;
  line-height: 3rem;
  color: vars.$color-text;
  text-align: center;
  border: 1px solid #4c4c4c;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  transition: background-color 0.15s ease-out;

  &::after {
    position: absolute;
    right: 0;
    bottom: 4px;
    left: 0;
    display: block;
    width: calc(100% - 16px);
    height: 2px;
    margin: auto;
    content: '';
    background: rgba(255, 255, 255, 0.27);
    transition: transform 0.2s vars.$ease-out-quart;
    transform: scaleX(0);
    transform-origin: center;
  }

  &.is-selected {
    padding: 0 0 0 6px;
    font-weight: vars.$font-weight-semi-bold;
    background-color: vars.$color-bg-1;
    border-width: 3px;

    &::after {
      transform: scaleX(1);
    }

    .AreaTabSelector-sample {
      top: 13px;
      left: 6px;
      transform: scale(1.25);
    }
  }
}
</style>
