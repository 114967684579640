<template>
  <div id="jepx-chart" class="JepxChart">
    <div class="contentHeading">
      <h2 class="contentHeading-title">JEPXスポット市場 価格推移</h2>
    </div>

    <div class="JepxChart-body">
      <div class="tab-area">
        <AreaTabSelector v-model="selectedAreaId" :areas="areas" />
        <div>
          <a
            class="commonButton t-chartaccent detailButton"
            href="/markets/price_checker#timeseries"
            target="_blank"
            >詳細分析</a
          >
        </div>
      </div>
      <div v-if="selectedAreaId" class="JepxChart-chartWrapper">
        <div class="JepxChart-chartWrapper-inner">
          <JepxMinmaxChartLegend
            :areas="areas"
            :current-area="selectedArea"
            :current-color="selectedAreaColor"
          />
          <JepxMinmaxChart
            :areas="areas"
            :the-items="theItems"
            :current-area-key="selectedAreaId"
            :current-color="selectedAreaColor"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JepxMinmaxChart from '../../components/JepxMinmaxChart'
import AreaTabSelector from '../../components/AreaTabSelector'
import JepxMinmaxChartLegend from '../../components/JepxMinmaxChart/JepxMinmaxChartLegend'

import { areasWithSystem } from '../../data/areas'
import hexToRgb from '../../functions/hexToRgb'

export default {
  name: 'JepxChart',
  components: {
    AreaTabSelector,
    JepxMinmaxChart,
    JepxMinmaxChartLegend,
  },
  data() {
    return {
      selectedAreaId: null,
    }
  },
  computed: {
    areas() {
      return areasWithSystem()
    },
    areaMap() {
      return Object.fromEntries(this.areas.map((area) => [area.id, area]))
    },
    theItems() {
      return gon.jepx_prices
    },
    selectedArea() {
      return this.areaMap[this.selectedAreaId]
    },
    selectedAreaColor() {
      if (!this.selectedArea) return {}

      const hex = this.selectedArea.chart.color
      const rgb = hexToRgb(hex).toString()
      return {
        point: hex,
        upperAverage: `rgba(${rgb}, 0.5)`,
        lowerAverage: `rgba(${rgb}, 0.25)`,
      }
    },
  },
  created() {
    this.selectAreaId(this.areas[0].id)
  },
  methods: {
    selectAreaId(areaId) {
      this.selectedAreaId = areaId
    },
  },
}
</script>

<style lang="scss" scoped>
@use "../../css/foundation/vars";
@import '../../css/vue_modules/content_heading';

.JepxChart {
  border-radius: 4px;

  &:not(:first-child) {
    margin-top: 50px;
  }
}

.JepxChart-body {
  padding: 20px 0;
  background: vars.$color-bg-header;
}

.JepxChart-chartWrapper {
  position: relative;
  overflow-y: hidden;

  &::before {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 344px;
    content: '';
    background: linear-gradient(to bottom, #343441, #0b0b0f 20%, vars.$color-bg-header 50%);
  }
}

.JepxChart-chartWrapper-inner {
  position: relative;
  width: 1072px;
  padding: 14px 0 0;
}

.JepxChart-title {
  font-size: 1.2rem;
  font-weight: bold;
}
.tab-area {
  display: flex;
}
.detailButton {
  position: absolute;
  top: 7.9rem;
}
</style>
