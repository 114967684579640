export default function areas() {
  // 分断情報で使用するため、東から順に配列で並べている
  return [
    {
      area_order_id: 1,
      id: 'hepco',
      name: '北海道',
      chart: { color: '#50FE86' },
      positionForSunlight: [43.06, 141.3283],
      // map: { x: -526, y: 26, scale: 2.8 },
    },
    {
      area_order_id: 2,
      id: 'tohoku-epco',
      name: '東北',
      chart: { color: '#FFC33B' },
      positionForSunlight: [38.2617, 140.8967],
      // map: { x: -480, y: -256, scale: 3 },
    },
    {
      area_order_id: 3,
      id: 'tepco',
      name: '東京',
      chart: { color: '#FD4174' },
      positionForSunlight: [35.69, 139.76],
      // map: { x: -788, y: -888, scale: 5 },
    },
    {
      area_order_id: 4,
      id: 'chuden',
      name: '中部',
      chart: { color: '#3B7FFA' },
      positionForSunlight: [35.1667, 136.965],
      // map: { x: -568, y: -826, scale: 4.5 },
    },
    {
      area_order_id: 5,
      id: 'rikuden',
      name: '北陸',
      chart: { color: '#F86E5A' },
      positionForSunlight: [36.7083, 137.2017],
      // map: { x: -458, y: -250, scale: 2.8 },
    },
    {
      area_order_id: 6,
      id: 'kepco',
      name: '関西',
      chart: { color: '#86FFEC' },
      positionForSunlight: [34.6817, 135.5183],
      // map: { x: -458, y: -250, scale: 2.8 },
    },
    {
      area_order_id: 7,
      id: 'energia',
      name: '中国',
      chart: { color: '#BA1FEA' },
      positionForSunlight: [34.3983, 132.4617],
      // map: { x: -458, y: -250, scale: 2.8 },
    },
    {
      area_order_id: 8,
      id: 'yonden',
      name: '四国',
      chart: { color: '#FFFD38' },
      positionForSunlight: [33.8433, 132.7767],
      // map: { x: -458, y: -250, scale: 2.8 },
    },
    {
      area_order_id: 9,
      id: 'kyuden',
      name: '九州',
      chart: { color: '#F68EFB' },
      positionForSunlight: [33.5817, 130.375],
      // map: { x: -458, y: -250, scale: 2.8 },
    },
  ]
}

export function areasWithOkiden() {
  return [
    ...areas(),
    {
      area_order_id: 10,
      id: 'okiden',
      name: '沖縄',
      chart: { color: '#5352F9' },
      positionForSunlight: [26.2067, 127.685],
    },
  ]
}

export function areasWithSystem() {
  return [
    {
      area_order_id: 0,
      id: 'system',
      name: 'システム',
      chart: { color: '#FFFFFF' },
    },
    ...areas(),
  ]
}
