import Vue from 'vue'
import JepxChart from '../pages/JepxChart'

require('../functions/bugsnag')

document.addEventListener('DOMContentLoaded', () => {
  // eslint-disable-next-line no-unused-vars
  const app = new Vue({
    el: '#jepx-chart',
    render: (h) => h(JepxChart),
  })
})
