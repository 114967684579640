<template>
  <div class="JepxMinmaxChartLegend">
    <p class="JepxMinmaxChartLegend-y2Label">
      <span class="JepxMinmaxChartLegend-y2Label-unit">(円/kWh)</span>
    </p>
    <div class="JepxMinmaxChartLegend-colorWrapper">
      <div class="JepxMinmaxChartLegend-colorWrapper-item">
        <ul class="JepxMinmaxChartLegend-colorList">
          <li class="JepxMinmaxChartLegend-colorItem">
            <span
              class="JepxMinmaxChartLegend-colorItem-sample"
              :style="{
                'border-bottom-color': currentColor.upperAverage,
                'border-bottom-style': 'solid',
              }"
            ></span
            >最高値
          </li>
          <li class="JepxMinmaxChartLegend-colorItem">
            <span
              class="JepxMinmaxChartLegend-colorItem-sample"
              :style="{
                'border-bottom-color': currentColor.upperAverage,
                'border-bottom-style': 'dashed',
              }"
            ></span
            >平均値
          </li>
          <li class="JepxMinmaxChartLegend-colorItem">
            <span
              class="JepxMinmaxChartLegend-colorItem-sample"
              :style="{
                'border-bottom-color': currentColor.lowerAverage,
                'border-bottom-style': 'solid',
              }"
            ></span
            >最安値
          </li>
        </ul>
      </div>
    </div>
    <p class="JepxMinmaxChartLegend-y2Label">
      <span class="JepxMinmaxChartLegend-y2Label-unit">(円/kWh)</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'JepxMinmaxChartLegend',
  props: {
    areas: {
      type: Array,
      required: true,
    },
    currentArea: {
      type: Object,
      required: true,
    },
    currentColor: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@use "../../css/foundation/vars";

.JepxMinmaxChartLegend {
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: 0 16px 0 36px;
  color: vars.$color-text;
}

.JepxMinmaxChartLegend-colorWrapper {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0 2em;
}

.JepxMinmaxChartLegend-colorWrapper-item {
  display: flex;
  justify-content: stretch;

  & + & {
    margin-left: 2.4rem;
  }
}

.JepxMinmaxChartLegend-colorWrapper-title {
  font-size: 1rem;
  font-weight: bold;
}

.JepxMinmaxChartLegend-colorList {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: 1.2rem;
}

.JepxMinmaxChartLegend-colorItem-sample {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 12px;
  height: 1px;
  margin: auto;
  border-bottom-width: 1px;
}

.JepxMinmaxChartLegend-colorItem {
  position: relative;
  display: block;
  padding-left: 16px;
  font-size: 1rem;

  & + & {
    margin-left: 16px;
  }
}

.JepxMinmaxChartLegend-y2Label {
  flex: 0 0 auto;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: right;
}

.JepxMinmaxChartLegend-y2Label-unit {
  font-size: 1rem;
  font-weight: vars.$font-weight-medium;
}
</style>
