/**
 * JEPXグラフのY軸のステップ間隔を算出する
 * @param {number} max - グラフに与えられる最大値
 * @returns {number}
 */
export default function getStepSize(max) {
  return stepThresholds().find((obj) => obj.min <= max).stepSize
}

/**
 * ステップ間隔とその始点の対応
 * @returns {({min: number, stepSize: number})[]}
 */
function stepThresholds() {
  return [
    {
      min: 1200,
      stepSize: 250,
    },
    {
      min: 600,
      stepSize: 200,
    },
    {
      min: 300,
      stepSize: 100,
    },
    {
      min: 120,
      stepSize: 50,
    },
    {
      min: 60,
      stepSize: 20,
    },
    {
      min: 30,
      stepSize: 10,
    },
    {
      min: 16,
      stepSize: 5,
    },
    {
      min: -Infinity,
      stepSize: 2,
    },
  ]
}
