<template>
  <div class="JepxMinmaxChart">
    <canvas ref="JepxMinmaxChart" width="1000" height="240" class="JepxMinmaxChart-canvas"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'

import withDelimiter from '../../functions/withDelimiter'
import getStepSize from '../../functions/chart/getStepSize'
import getMaxLimit from '../../functions/chart/getMaxLimit'
import createMinmaxDatasets from '../../functions/chart/createMinmaxDatasets'

export default {
  name: 'JepxMinmaxChart',
  props: {
    areas: {
      type: Array,
      required: true,
    },
    theItems: {
      type: Object,
      required: true,
    },
    currentAreaKey: {
      type: String,
      required: true,
    },
    currentColor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartObj: null,
    }
  },
  computed: {
    ctx() {
      return this.$refs.JepxMinmaxChart
    },
    pricesOfAreas() {
      return Object.values(this.theItems).sort(function (prev, next) {
        return prev.date > next.date ? 1 : -1
      })
    },
    prices() {
      return this.pricesOfAreas.map((price) => price[this.currentAreaKey])
    },
    maxPriceOfAllAreas() {
      return Math.max(
        ...this.pricesOfAreas.map((price) =>
          Math.max(...this.areas.map((area) => price[area.id].max))
        )
      )
    },
    maxPrice() {
      return Math.max(...this.maxDatasets)
    },
    minPrice() {
      return Math.min(...this.minDatasets)
    },
    stepSize() {
      return getStepSize(this.maxPriceOfAllAreas)
    },
    maxLimit() {
      return getMaxLimit(this.maxPriceOfAllAreas, this.stepSize)
    },
    maxDatasets() {
      return this.prices.map((price) => price.max)
    },
    minDatasets() {
      return this.prices.map((price) => price.min)
    },
    averageDatasets() {
      return this.prices.map((price) => price.average)
    },
    upperAverageDatasets() {
      return this.prices.map((price) => {
        const diff = price.max - price.average
        return Math.max(diff, 0)
      })
    },
    lowerAverageDatasets() {
      return this.prices.map((price) => {
        const diff = price.average - price.min
        return Math.max(diff, 0)
      })
    },
    lineDatasetsTemplate() {
      return {
        type: 'line',
        position: 'right',
        borderWidth: 1,
        hoverBorderWidth: 2,
        pointBorderWidth: 3,
        pointHoverBorderWidth: 3,
        pointRadius: 3.5,
        pointHoverRadius: 4.5,
        fill: false,
        lineTension: 0,
        xAxisID: 'x-period',
        yAxisID: 'y-price',
        spanGaps: true,
      }
    },
    barDatasetsTemplate() {
      return {
        type: 'bar',
        position: 'right',
        label: '',
        borderWidth: 0,
        barThickness: 4,
        xAxisID: 'x-period',
        yAxisID: 'y-price-stack',
      }
    },
    maxByDay() {
      return {
        ...this.lineDatasetsTemplate,
        data: this.maxDatasets,
        label: '最高値',
        borderColor: this.currentColor.upperAverage,
        pointBorderColor: this.currentColor.point,
        pointBackgroundColor: this.currentColor.point,
      }
    },
    averageByDay() {
      return {
        ...this.lineDatasetsTemplate,
        data: this.averageDatasets,
        label: '平均値',
        borderColor: this.currentColor.upperAverage,
        pointBorderColor: this.currentColor.point,
        pointBackgroundColor: '#000000',
        borderDash: [2, 3],
      }
    },
    minByDay() {
      return {
        ...this.lineDatasetsTemplate,
        data: this.minDatasets,
        label: '最安値',
        borderColor: this.currentColor.lowerAverage,
        pointBorderColor: this.currentColor.point,
        pointBackgroundColor: this.currentColor.point,
      }
    },
    upperAverageByDayForStack() {
      const color = this.currentColor.upperAverage
      return {
        ...this.barDatasetsTemplate,
        data: this.upperAverageDatasets,
        backgroundColor: color,
      }
    },
    lowerAverageByDayForStack() {
      const color = this.currentColor.lowerAverage
      return {
        ...this.barDatasetsTemplate,
        data: this.lowerAverageDatasets,
        backgroundColor: color,
      }
    },
    minByDayForStack() {
      const color = 'rgba(0,0,0,0)'
      return {
        ...this.barDatasetsTemplate,
        data: this.minDatasets,
        backgroundColor: color,
      }
    },
    minmaxDatasets() {
      return createMinmaxDatasets({
        maxValue: this.maxPrice,
        minValue: this.minPrice,
        xAxisID: 'x-period',
        yAxisID: 'y-price',
      })
    },
    labels() {
      return this.pricesOfAreas.map((price) => price.date)
    },
    options() {
      return {
        responsive: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              id: 'x-period',
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontFamily: '"Oswald", "M PLUS 1p", sans-serif',
                fontColor: '#fff',
                fontSize: 11,
                fontStyle: 'normal',
                padding: 8,
              },
              gridLines: {
                color: 'rgba(0,0,0,0)',
                zeroLineColor: '#fff',
                tickMarkLength: 0,
              },
              stacked: true,
            },
          ],
          yAxes: this.yAxes,
        },
        tooltips: {
          filter: (item) => {
            return this.datasetIndexesIsEnabledTooltip.includes(item.datasetIndex)
          },
        },
      }
    },
    datasetIndexesIsEnabledTooltip() {
      return [0, 1, 2]
    },
    yAxes() {
      return [
        {
          ...this.yAxisTemplate,
          id: 'y-price',
          position: 'left',
        },
        {
          ...this.yAxisTemplate,
          id: 'y-price-stack',
          position: 'right',
          stacked: true,
        },
      ]
    },
    yAxisTemplate() {
      return {
        type: 'linear',
        scaleLabel: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          fontColor: '#fff',
          fontSize: 10,
          fontStyle: 'bold',
          max: this.maxLimit,
          stepSize: this.stepSize,
          callback: (value) => {
            return value ? withDelimiter(value) : ''
          },
        },
        gridLines: {
          drawBorder: true,
          drawOnChartArea: false,
        },
      }
    },
    datasets() {
      return [
        this.minByDay,
        this.averageByDay,
        this.maxByDay,
        this.minByDayForStack,
        this.lowerAverageByDayForStack,
        this.upperAverageByDayForStack,
        this.minmaxDatasets.maxLine,
        this.minmaxDatasets.minLine,
      ]
    },
    params() {
      return {
        type: 'line',
        data: {
          labels: this.labels,
          datasets: this.datasets,
        },
        options: this.options,
      }
    },
    updateOption() {
      return { duration: 0, lazy: true, easing: 'linear' }
    },
  },
  watch: {
    theItems() {
      this.updateChart()
    },
    currentAreaKey() {
      this.updateChart()
    },
  },
  mounted() {
    this.chartObj = new Chart(this.ctx, this.params)
  },
  methods: {
    updateChart() {
      this.chartObj.data.datasets = this.datasets
      this.chartObj.data.labels = this.labels
      this.chartObj.options.scales.yAxes = this.yAxes
      this.$nextTick(() => {
        this.chartObj.update(this.updateOption)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@use "../../css/foundation/vars";

.JepxMinmaxChart {
  position: relative;
  color: vars.$color-text;
}

.JepxMinmaxChart-canvas {
  margin: 2px auto 0;
}
</style>
