export default function createMinmaxDatasets({
  maxValue,
  minValue,
  xAxisID,
  yAxisID,
  dataLength = 48,
}) {
  const maxLine = {
    ...minmaxDatasetsTemplate(xAxisID, yAxisID, '#FCC241'),
    label: 'max',
    data: new Array(dataLength).fill(maxValue),
  }

  const minLine = {
    ...minmaxDatasetsTemplate(xAxisID, yAxisID, '#B4C2FD'),
    label: 'min',
    data: new Array(dataLength).fill(minValue),
  }

  const dummyBar = {
    ...minmaxDatasetsTemplate(xAxisID, yAxisID, 'rgba(0,0,0,0)'),
    type: 'bar',
    label: '',
    data: new Array(dataLength).fill(0),
    barThickness: 45,
    barPercentage: 1,
  }

  return {
    maxLine,
    minLine,
    dummyBar,
  }
}

function minmaxDatasetsTemplate(xAxisID, yAxisID, color) {
  return {
    type: 'line',
    position: 'left',
    borderWidth: 1,
    hoverBorderWidth: 1,
    borderDash: [1, 2],
    pointBackgroundColor: '#000',
    pointBorderWidth: 1,
    pointHoverBorderWidth: 1,
    pointHoverRadius: 0,
    pointRadius: 0,
    borderColor: color,
    pointBorderColor: color,
    fill: false,
    lineTension: 0,
    xAxisID,
    yAxisID,
    spanGaps: true,
  }
}
